<template>
  <b-row>
    <b-col md="4">
      <b-card>
        <b-form-group label="Сумма, BYN">
          <b-form-input v-model="inputVal.preferable_amount" />
        </b-form-group>
        <b-form-group label="Срок, месяцев">
          <b-form-input v-model="inputVal.duration_months" />
        </b-form-group>
        <b-form-group label="Ставка, в год">
          <b-form-select
            v-model="inputVal.rate"
            :options="terms"
            text-field="title"
            value-field="value"
          />
        </b-form-group>
        <b-form-group label="Ставка, в месяц">
          <b-form-input v-model="rateMonth" disabled />
        </b-form-group>
        <b-form-group label="Ставка, в день">
          <b-form-input v-model="rateDay" disabled />
        </b-form-group>
        <div class="d-flex justify-content-between mt-1">
          <b-button variant="primary" @click="$emit('updateApplication')">
            Обновить
          </b-button>
          <b-button
            variant="outline-primary"
            :disabled="processingCreditHistory"
            @click="requestCreditHistory"
          >
            <b-spinner v-if="processingCreditHistory" small class="mr-1" />
            <feather-icon v-else icon="FileTextIcon" size="14" class="mr-1" />
            Запрос кредитной истории
          </b-button>
        </div>
      </b-card>
    </b-col>
    <b-col md="4">
      <b-card>
        <b-form-group label="Ежемесяный доход">
          <b-form-input v-model="inputVal.monthly_income" />
        </b-form-group>
        <b-form-group label="Ежемесячные расходы">
          <b-form-input v-model="inputVal.monthly_expense" />
        </b-form-group>
        <b-form-group label="Платежи по кредитам/займам/лизингу">
          <b-form-input v-model="inputVal.credit_payment" />
        </b-form-group>
        <template v-if="inputVal.pdn_rate"> {{ inputVal.pdn_rate }}% </template>
        <b-button variant="primary" @click="$emit('pdnCalculate')">
          Расcчитать ПДН
        </b-button>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'ApplicationClientTerms',
  props: ['value'],
  data() {
    return {
      processingCreditHistory: false,
      terms: [
        { title: 'До 3 мес, 5000, 120.45%', value: 1.2045 },
        { title: 'До 3 мес, 100000, 109.5%', value: 1.095 },
        { title: 'До 24 мес, 30000, 102.2%', value: 1.022 },
        { title: 'До 24 мес, 60000, 91.25%', value: 0.9125 },
        { title: 'До 24 мес, 100000, 65.7%', value: 0.657 },
      ],
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    rateMonth() {
      return this.inputVal.rate / 12
    },
    rateDay() {
      return this.inputVal.rate / 365
    },
  },
  methods: {
    async requestCreditHistory() {
      this.processingCreditHistory = true
      try {
        await this.$http.post('/credit-register/process/', {
          user_id: this.inputVal.user,
        })
        this.$toast.success('Запрос кредитной истории успешно отправлен')

        // Redirect to credit register page with filter applied
        this.$router.push({
          name: 'credit-register-list',
        })
      } catch (error) {
        this.$toast.error('Ошибка при запросе кредитной истории')
        console.error(error)
      } finally {
        this.processingCreditHistory = false
      }
    },
  },
}
</script>
