 <template>
  <div class="application-view">
    <b-row>
      <b-col md="12">
        <b-card>
          <application-control
            v-model="application"
            @updateApplication="updateApplication"
          />
        </b-card>
      </b-col>
      <b-col md="12">
        <b-tabs v-model="tab" fill pills>
          <b-tab title="Заемщик">
            <application-client-info-card
              v-if="tab === 0"
              :id="application.user"
              :application-view="true"
            />
          </b-tab>
          <b-tab title="Авто">
            <application-car-card v-if="tab === 1" v-model="application" />
          </b-tab>
          <b-tab title="Документы">
            <application-client-docs-card
              v-if="tab === 2"
              :id="application.user"
            />
          </b-tab>
          <b-tab title="Родственники">
            <application-relatives-card
              v-if="tab === 3"
              :user="application.user"
            />
          </b-tab>
          <b-tab title="Чек-лист">
            <application-check-list-card
              v-if="tab === 4"
              v-model="application"
              @updateApplication="updateApplication"
            />
          </b-tab>
          <b-tab title="Условия">
            <application-client-terms
              v-if="tab === 5"
              v-model="application"
              @updateApplication="updateApplication"
              @pdnCalculate="pdnCalculate"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ApplicationClientInfoCard from '@/views/applications/components/ApplicationClientInfoCard'
import ApplicationCarCard from '@/views/applications/components/ApplicationCarCard'
import ApplicationControl from '@/views/applications/components/ApplicationControl'
import ApplicationClientDocsCard from '@/views/applications/components/ApplicationClientDocsCard'
import ApplicationCheckListCard from '@/views/applications/components/ApplicaitonCheckListCard'
import ApplicationClientTerms from '@/views/applications/components/ApplicationClientTerms'
import ApplicationRelativesCard from '@/views/applications/components/ApplicationRelativesCard'

export default {
  name: 'ApplictionView',
  components: {
    ApplicationRelativesCard,
    ApplicationClientTerms,
    ApplicationCheckListCard,
    ApplicationClientDocsCard,
    ApplicationControl,
    ApplicationCarCard,
    ApplicationClientInfoCard,
  },
  data() {
    return {
      id: this.$route.params.appId,
      application: {},
      tab: 0,
    }
  },
  created() {
    this.getApplication()
  },
  methods: {
    async pdnCalculate() {
      const res = await this.$http.patch(`/admin/pdn-calculate/${this.id}/`, this.application)
      this.application = res.data
    },
    async getApplication() {
      const res = await this.$http.get(`admin/applications/${this.id}/`)
      this.application = res.data
    },
    async updateApplication() {
      try {
        const res = await this.$http.patch(
          `admin/applications/${this.id}/`,
          this.application
        )
        this.application = res.data
        this.$toast.success('Обновлено')
      } catch (e) {
        this.$toast.error('Ошибка')
      }
    },
  },
}
</script>
